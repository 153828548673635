var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import "./index.css";
var Loading = /** @class */ (function (_super) {
    __extends(Loading, _super);
    function Loading() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._el = null;
        _this.state = { width: 40, height: 40 };
        return _this;
    }
    Loading.prototype.componentDidMount = function () {
        if (this._el) {
            var width = this._el.offsetWidth, height = this._el.offsetHeight;
            if (width === 0) {
                this.setState({ width: "40%", height: "auto" });
            }
            else {
                if (width > height) {
                    height = height * 0.8;
                    width = height;
                }
                else {
                    width = width * 0.4;
                    height = width;
                }
                if (width > 100) {
                    width = height = 100;
                }
                this.setState({ width: width, height: height });
            }
        }
    };
    Loading.prototype.render = function () {
        var _this = this;
        var _a = this.props, error = _a.error, pastDelay = _a.pastDelay, retry = _a.retry;
        if (error) {
            console.log(error);
            return (React.createElement("span", { className: "rb-loading", onClick: retry }, "\u52A0\u8F7D\u7EC4\u4EF6\u51FA\u9519\uFF01\u70B9\u51FB\u91CD\u8BD5!"));
        }
        else if (pastDelay) {
            var _b = this.state, width = _b.width, height = _b.height;
            return (React.createElement("div", { ref: function (el) { return (_this._el = el); }, className: "rb-loading" },
                React.createElement("img", { src: "//cdn.yuandingyun.vip/load.gif", alt: "", style: { width: width, height: height } })));
        }
        else {
            return null;
        }
    };
    return Loading;
}(React.Component));
export default Loading;
